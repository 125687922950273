import get from 'lodash/get'

const IS_BROWSER = typeof window !== 'undefined'

export function readDjangoJson(id, defaultValue = null) {
  if (!IS_BROWSER) {
    return null
  }
  const element = document.getElementById(id)
  if (element) {
    return JSON.parse(element.textContent)
  }
  return defaultValue
}

export const USER = readDjangoJson('authenticated-user')

export const FORNITORE_CONFIG = readDjangoJson('fornitore_config')

const useIsSuperUser = get(USER, 'is_superuser', false)

const useIsStaff = get(USER, 'is_staff', false)

const userHasAgenteAccount = !!get(USER, 'agente', null)

const isOperatore = get(USER, 'operatore', true)

// NOTE: generic permission by User
export const PERMISSIONS = {
  viewOrders:
    useIsSuperUser ||
    userHasAgenteAccount ||
    get(USER, 'operatore.vede_ordini', false),

  addAttivita: isOperatore,

  // Only super user can delete orders
  deleteOrder: useIsSuperUser,
  // Only super user can edit orders
  editOrder: useIsSuperUser,

  addOrders: useIsSuperUser,

  adminOrders: useIsSuperUser,

  autoScheduleOrder: useIsSuperUser,

  viewOrderAllegati:
    useIsSuperUser ||
    userHasAgenteAccount ||
    get(USER, 'operatore.vede_ordini', false),

  updateOrderAllegati: useIsSuperUser,

  viewAgenteOnOrders: useIsSuperUser || get(USER, 'agente.is_capo_area', false),

  isCapoArea: get(USER, 'agente.is_capo_area', false),

  viewOrderFasi:
    useIsSuperUser ||
    (get(USER, 'operatore.vede_ordini', false) &&
      (get(USER, 'operatore.modifica_stato_fasi_ordine', false) ||
        get(USER, 'operatore.modifica_stato_fasi_ordine_fornitore', false))),

  viewPreventivi: useIsSuperUser || userHasAgenteAccount,

  viewPricesOnOrders: useIsSuperUser || userHasAgenteAccount,

  updateStatoFasiOrdine:
    useIsSuperUser || get(USER, 'operatore.modifica_stato_fasi_ordine', false),

  updateStatoFasiOrdineFornitore:
    useIsSuperUser ||
    get(USER, 'operatore.modifica_stato_fasi_ordine_fornitore', false),

  adminOrdiniFornitore: useIsSuperUser,

  viewOrderFornitore:
    useIsSuperUser ||
    get(USER, 'operatore.modifica_stato_fasi_ordine_fornitore', false),

  scheduleOrder: useIsSuperUser,

  adminFornitori: useIsSuperUser,

  adminPersonale: useIsSuperUser,

  adminUsers: useIsSuperUser && get(USER, 'info.can_admin_users', false),

  canViewLineaSaomad: useIsSuperUser || useIsStaff || get(USER, 'info.can_view_linea_saomad', true),

  adminAttivitaOperatori:
    useIsSuperUser && get(USER, 'info.can_view_attivita_operatori', false),

  adminApp: useIsSuperUser,

  viewStats: useIsSuperUser,

  viewFolders: useIsSuperUser || !!get(USER, 'app_account', null) || !!get(USER, 'operatore', null),

  viewOrderControlli: useIsSuperUser,

  viewMarcaturaCe: isOperatore,

  writeOrderCheckList: useIsSuperUser,

  confermaOrdini: useIsSuperUser,
}

export const SETTINGS = readDjangoJson('dj-settings', {
  PAGE_SIZE: 10,
})

let csrfElement
if (IS_BROWSER) {
  csrfElement = document.querySelector('[name=csrfmiddlewaretoken]')
}
export const CSRF = csrfElement ? csrfElement.value : null
