import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import { Formik, Field } from 'formik'
import { InputField } from '../Form/Fields'
import { transformErrorForForm } from '../Form/Fields/utils'
import { useRunRj } from 'react-rocketjump'
import { AgentiState } from '../../apps/AdminPreventiviApp/localstate'

const InitialAgenteValues = {
  codice: '',
  capo_area: '',
  tipo_agente: '',
}

const AgenteSchema = Yup.object().shape({
  codice: Yup.string().required(),
})

export default function AgenteForm({ user, save, onDelete, setAgenteForm }) {
  const agenteValues = useMemo(() => {
    if (!user.agente) {
      return InitialAgenteValues
    }
    return {
      id: user.agente.id,
      capo_area: user.agente.capo_area ? user.agente.capo_area.id : '',
      codice: user.agente.codice,
      tipo_agente: user.agente.tipo_agente,
    }
  }, [user])

  const [{ data: agenti }] = useRunRj(AgentiState)

  return (
    <Formik
      validationSchema={AgenteSchema}
      onSubmit={(values, actions) => {
        return save({
          ...values,
          user: user.id,
        }).then(
          (data) => {
            actions.setSubmitting(false)
            // Update created id
            if (!values.id) {
              actions.setFieldValue('id', data.id)
            }
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      initialValues={agenteValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="p-0 m-0">
          <Field
            autoComplete="off"
            component={InputField}
            label="Codice"
            name="codice"
          />
          <Field
            label="Capo area"
            type="select"
            name="capo_area"
            component={InputField}
          >
            <option value={''}></option>
            {agenti &&
              agenti.map((agente) => (
                <option key={agente.id} value={agente.id}>
                  {agente.user.first_name} {agente.user.last_name}
                </option>
              ))}
          </Field>
          <Field
            label="Tipo agente"
            type="select"
            name="tipo_agente"
            component={InputField}
          >
            <option value={''}></option>
            <option value={"B2C"}>B2C</option>
            <option value={"B2B"}>B2B</option>
          </Field>
          <div className="mt-4 d-flex justify-content-between">
            {user.agente && (
              <Button disabled={isSubmitting} onClick={onDelete} color="danger">
                <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                Elimina
              </Button>
            )}
            {!user.agente && (
              <Button
                onClick={() => setAgenteForm(false)}
                disabled={isSubmitting}
                className="ml-2"
              >
                Annulla
              </Button>
            )}
            <Button
              disabled={isSubmitting}
              className="ml-2"
              color="success"
              type="submit"
            >
              Salva
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
