import React, { useMemo, useState } from 'react'
import { ClientiStatsState, OrdersDomainState } from './localstate'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, ButtonGroup } from 'reactstrap'
import { useRunRj } from 'react-rocketjump'
import { AgentiState } from '../AdminPreventiviApp/localstate'
import moment from 'moment'
import DateRangeQsFilter from '../../components/Planner/DateRangeQsFilter'
import Column from '../../components/Column'
import { useHistory } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import Select from 'react-select'


export default function StatsClienti() {
  const [
    // Data State
    { clienti, pagination, loading, deleting, totals },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      goToPage,
      onFilterChangeDebounced,
      onFilterChange,
      onFiltersChange,
    },
  ] = useDataTable(ClientiStatsState, () => ({
    search: '',
    agenti: [],
    ordering: 'nome',
    page_size: 100,
    tipo_cliente: '',
    data_inizio: moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD'),
    data_fine: moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD'),
  }))

  const [{ data: agenti }] = useRunRj(AgentiState)

  const agentiOptions = useMemo(() => {
    if (!agenti) return []
    const agi = agenti.map((agente) => ({
      value: agente.id,
      label: agente.user.first_name + ' ' + agente.user.last_name,
    }))
    // return sorted agenti
    return agi.sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })
  }, [agenti])

  const [{ data: ordersDomain }] = useRunRj(OrdersDomainState)

  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, 'ordering'),
  }

  const history = useHistory()

  const [typeStat, setTypeStat] = useState('table')

  const orderedClienti = useMemo(() => {
    if (!clienti) return []
    // return agenti ordered by differenza_netto_budget desc and null or undefined at the end
    return clienti.sort((a, b) => {
      if (a.differenza_netto_budget === null) return 1
      if (b.differenza_netto_budget === null) return -1
      return b.differenza_netto_budget - a.differenza_netto_budget
    })
  }, [agenti])

  const selectedAgenti = useMemo(() => {
    if (!filters.agenti || !agenti) {
      return []
    }
    if (typeof filters.agenti === 'string') {
      return filters.agenti.split(',').map((id) => ({
        value: id,
        label:
          agenti.find((agente) => agente.id === Number(id))?.user.first_name +
          ' ' +
          agenti.find((agente) => agente.id === Number(id))?.user.last_name,
      }))
    } else {
      return filters.agenti.map((id) => ({
        value: id,
        label:
          agenti.find((agente) => agente.id === Number(id))?.user.first_name +
          ' ' +
          agenti.find((agente) => agente.id === Number(id))?.user.last_name,
      }))
    }
  }, [filters.agenti, agenti])

  const agentiForQs = useMemo(() => {
    if(!filters.agenti || !agenti) {
      return ''
    }
    if (typeof filters.agenti === 'string') {
      return '&agenti=' + filters.agenti
    } else {
      return filters.agenti.map((id) => '&agenti=' + id).join('')
    }
  }, [filters.agenti, agenti])

  return (
    <div className="container-fluid">
      {clienti === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {clienti && (
        <>
          <h3 className="mt-2">Statistiche Clienti</h3>
          <div className="row mt-4 align-items-center">
            <div className="col-md-3">
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="data_inizio"
                endField="data_fine"
                showClearDates={false}
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </div>
            <div className="col-md-2">
              <select
                name="tipo_cliente"
                value={filters.tipo_cliente}
                className="form-control form-control-sm"
                onChange={onFilterChange}
              >
                <option value="">Tipo cliente</option>
                {ordersDomain &&
                  ordersDomain.tipi_cliente.map((tipo) => (
                    <option key={tipo} value={tipo}>
                      {tipo}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-5">
              <Select
                name="agenti"
                isMulti
                placeholder={'Filtra per agenti'}
                options={agentiOptions}
                classNamePrefix="multi-select"
                hideSelectedOptions={false}
                isOptionSelected={(option) =>
                  filters.agenti && filters.agenti.includes(option.value)
                }
                value={selectedAgenti}
                className="stats-multi-select select-multi-options text-nowrap w-100"
                onChange={(newValues) => {
                  onFiltersChange({
                    ...filters,
                    agenti: newValues ? newValues.map((v) => v.value) : [],
                  })
                }}
              />
            </div>
            <div className="col-md-2">
              {/* button for reset */}
              <Button
                onClick={() =>
                  onFiltersChange({
                    tipo_cliente: '',
                    agenti: [],
                    data_inizio: moment()
                      .subtract(1, 'month')
                      .startOf('month')
                      .format('YYYY-MM-DD'),
                    data_fine: moment()
                      .subtract(1, 'month')
                      .endOf('month')
                      .format('YYYY-MM-DD'),
                  })
                }
                size="sm"
                outline
                color="secondary"
              >
                Reset
              </Button>
            </div>
          </div>
          {/*  switch agenti / clienti */}
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="border-bottom d-flex align-items-center w-100">
                <div
                  className="font-weight-bold border-bottom border-primary pointer"
                  onClick={() =>
                    history.push(
                      '/statistiche-clienti?data_inizio=' +
                        filters.data_inizio +
                        '&data_fine=' +
                        filters.data_fine +
                        agentiForQs
                        
                    )
                  }
                >
                  Clienti
                </div>
                <div
                  className="ml-4 pointer"
                  onClick={() =>
                    history.push(
                      '/statistiche-agenti?data_inizio=' +
                        filters.data_inizio +
                        '&data_fine=' +
                        filters.data_fine +
                        agentiForQs
                        
                    )
                  }
                >
                  Agenti
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <ButtonGroup>
                  <Button
                    color={'primary'}
                    onClick={() => setTypeStat('table')}
                    active={typeStat === 'table'}
                    outline={typeStat === 'chart'}
                  >
                    Tabella
                  </Button>
                  <Button
                    color={'primary'}
                    onClick={() => setTypeStat('chart')}
                    active={typeStat === 'chart'}
                    outline={typeStat === 'table'}
                  >
                    Grafico
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          {typeStat === 'table' && (
            <div className="table-responsive">
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <td>
                      <input
                        name="search"
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={'Ricerca libera'}
                        value={filters && filters.search}
                        onChange={onFilterChangeDebounced}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">
                      {totals.totale_ordini && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_ordini}
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      {totals.totale_serramenti && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_serramenti}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_listino && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_listino}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_sconto && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_sconto.toFixed(2)} %
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_media_ordini && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_media_ordini.toFixed(2)} %
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_media_serramenti && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_media_serramenti.toFixed(2)} %
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_netto && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_netto}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_budget && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_budget.toFixed(2)}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_differenza_netto_budget ? (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_differenza_netto_budget.toFixed(2)} %
                        </span>
                      ) : (
                        <span className="badge badge-primary">0 %</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <Column {...columnProps} field="nome">
                      Nome
                    </Column>
                    <Column {...columnProps} field="codice">
                      Codice
                    </Column>
                    <Column {...columnProps} field="tipo_cliente">
                      Tipo cliente
                    </Column>
                    <Column {...columnProps} field="agente__user__first_name">
                      Agente
                    </Column>
                    <Column {...columnProps} field="numero_ordini">
                      N° comm.
                    </Column>
                    <Column {...columnProps} field="numero_serramenti">
                      N° serr.
                    </Column>
                    <Column {...columnProps} field="listino_serramenti">
                      Listino
                    </Column>
                    <Column {...columnProps} field="sconto_serramenti">
                      Sconto
                    </Column>
                    <Column {...columnProps} field="media_ordini">
                      Media commesse
                    </Column>
                    <Column {...columnProps} field="media_serramenti">
                      Media € serramento
                    </Column>
                    <Column {...columnProps} field="netto_serramenti">
                      Netto
                    </Column>
                    <Column {...columnProps} field="budget_cliente">
                      Budget
                    </Column>
                    <Column {...columnProps} field="differenza_netto_budget">
                      Diff.
                    </Column>
                  </tr>
                </thead>
                <tbody className="stats-table">
                  {clienti.map((cliente) => (
                    <tr key={cliente.id}>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.nome}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.codice}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.tipo_cliente}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.agenti.map((agente, i) => (
                          agente === null ? '' :
                          <div key={i}>
                            {agenti?.find((a) => a.codice === agente)?.user.first_name +
                              ' ' + agenti?.find((a) => a.codice === agente)?.user.last_name}
                          </div>
                        ))}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.numero_ordini}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.numero_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.listino_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.sconto_serramenti} %
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.media_ordini} 
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.media_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.netto_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {cliente.budget_cliente}
                      </td>
                      <td
                        style={{
                          background:
                            cliente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              width: 70,
                            }}
                          >
                            {
                              // Differenza in percentuale tra budget e netto
                              cliente.differenza_netto_budget ?? 0
                            }
                            {' %'}
                          </div>
                          {/*  bar chart based on the difference with color in base of the percentage and the value 
                      0-20 #CA0A0A
                      20-40 #E77D1C
                        40-60 #F9BC20
                        60-80 #8DCA0A
                        80-100 #04A90A
                        */}

                          <div
                            style={{
                              height: 5,
                              width: 150,
                              background: '#D7E7F8',
                              position: 'relative',
                              borderRadius: 2,
                            }}
                          >
                            <div
                              className="position-absolute"
                              style={{
                                height: 5,
                                width: `${cliente.differenza_netto_budget}%`,
                                maxWidth: '100%',
                                background:
                                  cliente.differenza_netto_budget < 20
                                    ? '#CA0A0A'
                                    : cliente.differenza_netto_budget < 40
                                    ? '#E77D1C'
                                    : cliente.differenza_netto_budget < 60
                                    ? '#F9BC20'
                                    : cliente.differenza_netto_budget < 80
                                    ? '#8DCA0A'
                                    : '#04A90A',
                                borderRadius: 2,
                              }}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {typeStat === 'chart' && (
            <ResponsiveContainer width="100%" height={900} className={'mt-4'}>
              <BarChart
                layout="vertical"
                data={orderedClienti}
                margin={{ top: 5, right: 30, left: 80, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="differenza_netto_budget" />
                <YAxis
                  type="category"
                  dataKey="nome"
                  tickFormatter={(value) => value.substring(0, 18)}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip formatter={(value) => value + ' %'} />
                {/* <Legend /> */}
                <Bar dataKey="differenza_netto_budget">
                  {orderedClienti.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.differenza_netto_budget < 20
                          ? '#CA0A0A'
                          : entry.differenza_netto_budget < 40
                          ? '#E77D1C'
                          : entry.differenza_netto_budget < 60
                          ? '#F9BC20'
                          : entry.differenza_netto_budget < 80
                          ? '#8DCA0A'
                          : '#04A90A'
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </div>
  )
}
